import React, {useEffect, useMemo, useRef, useState} from "react";
import {
    CardHeader,
    Col,
    Row,
    CardBody,
    Card,
    Button,
    Nav, NavItem, NavLink, TabContent, TabPane, UncontrolledCarousel,
    Table
} from "reactstrap";
import {toast} from "react-toastify";
import {Link, useHistory, useParams} from "react-router-dom";
import PageContent from "../../Layouts/PageContent";
import env from "../../helpers/env";
import {useAxios, useFetchDataList} from "../../hooks";
import AttachmentForm from "./AttachmentForm";
import TransferForm from "./TransferForm";
import OutPropertyForm from "./OutPropertyForm";
import {
    downloadReport,
    formatDate,
    formatDatInput,
    serviceOrDirection,
    userHavePermissions
} from "../../helpers";

//SimpleBar
import classnames from "classnames";
import PrinterLineIcon from "remixicon-react/PrinterLineIcon";
import {useReactToPrint} from "react-to-print";
import {PropertyDetailsPrint} from "./PropertyDetailsPrint";
import EditLineIcon from "remixicon-react/EditLineIcon";
import axios from "axios";

const DetailProperty = () => {
    document.title ="Biens | "+env('NAME');

    // Attachment && Detachments
    const [showAttachForm, setShowAttachForm] = useState(false);
    const [showDetachForm, setShowDetachForm] = useState(false);
    const [createData, setCreateData] = useState([]);
    const [attachments, setAttachments] = useState([]);
    const componentRef = useRef();
    const history = useHistory()

    // Transfer
    const [submitting, setSubmitting] = useState(false);
    const [showTransferForm, setShowTransferForm] = useState(false);

    // Sortir
    const [showOutProperties, setShowOutProperties] = useState(false);

    // Edit Transfer
    const [editData, setEditData] = useState(null);
    // Amortissement
    const [ownerPlan, setOwnerPlan] = useState([]);

    const {id} = useParams();
    const {axios} = useAxios();

    const formatItem = (item) => {
        return {
            ...item,
            checked: false,
        }
    };

    const {list, setList} = useFetchDataList(`/api/properties/${id}`, (responseData) => {
        setAttachments(responseData.attachments.map(formatItem))
        function fetchData (sendData) {
            axios.post('/api/amortization-plan', sendData)
                .then(({data}) => {
                    setOwnerPlan(data);
                })
                .catch(error => {})
            ;
        }

        if (responseData?.origin && responseData.origin !== "Location") {
            const sendData = {
                type_amortization : responseData?.amortization_property?.type_amortization,
                acquisition_date : formatDatInput(responseData?.date_of_purchase),
                number_of_years : responseData?.amortization_property?.number_of_years || 5,
                purchase_amount : responseData?.amortization_property?.acquisition_amount,
                residual_value : responseData?.amortization_property?.residual_value
            };
            fetchData(sendData);
        }
        return responseData;
    });

    const deleteItem = async deleteItem => {
        await axios.delete(`/api/properties/${id}/transferts/${deleteItem.id}`)
            .then(() => {
                toast.success("Succès de la suppression");
                setList({
                    ...list,
                    assignments: list.assignments.filter(item => item.id !== deleteItem.id)
                });
            })
            .catch(() => {
                toast.error("Echec de la suppression")
            })
        ;
    };

    useEffect(() => {
        axios.get(`/api/properties/${id}/attachments/create`)
            .then(response => {
                setCreateData(response.data.map(formatItem))
            })
            .catch(() => toast.error("Something is wrong"))
        ;
    }, []);

    const [customActiveTab, setcustomActiveTab] = useState("1");
    const toggleCustom = tab => {
        if (customActiveTab !== tab) {
            setcustomActiveTab(tab);
        }
    };
    const handlePrint = () => {
        setSubmitting(true)
        axios.get(`/api/print/property/${id}`,
            {
                method: 'GET',
                responseType: 'blob'
            })
            .then(({data}) => {
                downloadReport(data,`fiche-bien-${list?.standard?.name}`,'pdf')
                toast("Impression effectuée avec succès", {type: 'success'});
            })
            .catch(error => {
                toast( "Echec d'exportation", {type: 'error'});
            })
            .finally(() => setSubmitting(false))
        ;

    };

    const planArray = useMemo(() => {
        return (typeof list === "object") && (list.origin !== "Location") ? (
            <div className="mt-3">
                <br/>
                <h5 className="mb-2">
                    {list.
                        amortization_property?.type_amortization === "Dégressif" ?
                        "Amortissement dégressif à taux décoirssant" : "Amortissement à taux linéaire"
                    }
                </h5>
                <br/>
                <Col xl={12}>
                    <div className="table-responsive">
                        <Table className="table-hover align-middle table-nowrap mb-0">
                            <thead className="text-muted table-light">
                            <tr>
                                <th scope="col">Année</th>
                                <th scope="col">Base amortissable</th>
                                {list.amortization_property?.type_amortization === "Dégressif" && (
                                    <th scope="col">Taux d'amortissement</th>
                                )}
                                <th scope="col">Annuité d'amortissement</th>
                                <th scope="col">Amortissement cumulé</th>
                                <th scope="col">Valeur nette comptable</th>
                            </tr>
                            </thead>

                            <tbody>
                            {(ownerPlan).map((item, key) => (
                                <tr key={key}>
                                    <td>{item.date}</td>
                                    <td>{item.base}</td>
                                    {list.amortization_property?.type_amortization === "Dégressif" && (
                                        <td>{item.taux}</td>
                                    )}
                                    <td>{item.annuity}</td>
                                    <td>{item.cumulAnnuity}</td>
                                    <td>{item.comptableNette}</td>
                                </tr>
                            ))}
                            </tbody>
                        </Table>
                    </div>
                </Col>
            </div>
        ) : null;
    }, [list, ownerPlan]);

    const fileTemplate = useMemo(() => {
        return <div className="pt-3 border-top border-top-dashed mt-4">
            <h6 className="mb-3 fw-semibold text-uppercase">Liste des fichiers attachés</h6>
            <Row className="g-3">
                {list && list.uploads ? list.uploads.filter(item => item.type === "contract_files").map((item, index) => (
                    <Col lg={6} key={index}>
                        <div className="border rounded border-dashed p-2">
                            <div className="d-flex align-items-center">
                                <div className="flex-grow-1 overflow-hidden">
                                    <h5 className="fs-13 mb-1"><Link to="#" className="text-body text-truncate d-block">{item.url.split('/')[1]}</Link></h5>
                                </div>
                                <div className="flex-shrink-0 ms-2">
                                    <div className="d-flex gap-1">
                                        <a target={"_blank"} href={item.link} download={true} type="button" className="btn btn-icon text-muted btn-sm fs-18"><i className="ri-download-2-line"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                )) : null}
            </Row>
        </div>
    }, [list])

    return (
        <PageContent title={"Bien"} pageTitle={"Détail d'un bien"}>
            <Row className="mb-3" ref={componentRef}>
                <Col xl={9}>
                    <Card>
                        <CardBody>
                            <Row className="g-4">
                                <Col lg={3}>
                                    <div className="sticky-side-div">
                                        <Card className="ribbon-box border shadow-none right">
                                            <img src={list?.qr_code?.link} alt="" className="img-fluid rounded" />
                                        </Card>
                                        <div className="hstack gap-2">
                                            <Link
                                                className={"btn btn-success w-100"}
                                                to="/inputs">
                                                Quitter
                                            </Link>
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={9}>
                                    <div>
                                        <span className="badge badge-soft-info mb-3 fs-12"><i className="ri-eye-line me-1 align-bottom"></i> {attachments.length} bien{attachments.length ? 's' : ''} attaché{attachments.length ? 's' : ''}</span>
                                        <h4>{list?.standard?.name}</h4>
                                        <br/>
                                        <h6>Utilisateur actuel</h6>

                                        <div className="hstack gap-3 flex-wrap">
                                            <div className="text-muted">Nom : <Link to="#"
                                                                                    className="text-primary fw-medium">{list?.assignment_active?.staff?.last_name} {list?.assignment_active?.staff?.first_name}</Link>
                                            </div>
                                            <div className="vr"></div>
                                            <div
                                                className="text-muted">{serviceOrDirection(list?.assignment_active?.managementable_type)} : <span
                                                className="text-body fw-medium">{list?.assignment_active?.staff?.staffable?.name}</span>
                                            </div>
                                            <div className="vr"></div>
                                            <div className="text-muted">Téléphone : <span
                                                className="text-body fw-medium">{list?.assignment_active?.staff?.telephone}</span>
                                            </div>
                                            <div className="vr"></div>
                                            <div className="text-muted">Source de Financement : <span
                                                className="text-body fw-medium">{list?.origin}</span></div>
                                            <div className="vr"></div>
                                            <div className="text-muted">État : <span
                                                className="text-body fw-medium">{list?.etat?.name}</span></div>
                                            <div className="vr"></div>
                                            <div className="text-muted">Date d'aquisition : <span
                                                className="text-body fw-medium">{formatDate(list?.date_of_purchase)}</span>
                                            </div>
                                            <div className="vr"></div>
                                            <div className="text-muted">Durée d'amortissement : <span
                                                className="text-body fw-medium">{list?.amortization_property?.number_of_years} an(s)</span>
                                            </div>
                                            <div className="vr"></div>
                                            <div className="text-muted">Date de mise en service : <span
                                                className="text-body fw-medium">{formatDate(list?.date_of_use)}</span>
                                            </div>
                                        </div>

                                        <div className="mt-4 text-muted">
                                            <h5 className="fs-14">Description du bien :</h5>
                                            <p>{list?.description}</p>
                                        </div>

                                        <UncontrolledCarousel
                                            interval={4000}
                                            indicators={false}
                                            items={list && list.uploads ? list.uploads.filter(item => item.type === "properties").map((item, index) => {
                                                return {
                                                    altText: " ",
                                                    caption: " ",
                                                    key: index + 1,
                                                    src: item.link,
                                                }
                                            }) : []}
                                        />
                                    </div>
                                </Col>

                                <Col lg={12}>
                                    <div className="product-content mt-5">
                                        <h5 className="fs-14 mb-3">Autres informations :</h5>

                                        <Nav tabs className="nav-tabs-custom nav-success">
                                            <NavItem>
                                                <NavLink
                                                    style={{ cursor: "pointer" }}
                                                    className={classnames({
                                                        active: customActiveTab === "1",
                                                    })}
                                                    onClick={() => {
                                                        toggleCustom("1");
                                                    }}
                                                >
                                                    Rattachements
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink
                                                    style={{ cursor: "pointer" }}
                                                    className={classnames({
                                                        active: customActiveTab === "2",
                                                    })}
                                                    onClick={() => {
                                                        toggleCustom("2");
                                                    }}
                                                >
                                                    Liste des transferts
                                                </NavLink>
                                            </NavItem>
                                            {
                                                list?.standard?.depreciation_period > 0  &&
                                                <NavItem>
                                                    <NavLink
                                                        style={{ cursor: "pointer" }}
                                                        className={classnames({
                                                            active: customActiveTab === "3",
                                                        })}
                                                        onClick={() => {
                                                            toggleCustom("3");
                                                        }}
                                                    >
                                                        Amortissement
                                                    </NavLink>
                                                </NavItem>
                                            }
                                                <NavItem>
                                                    <NavLink
                                                        style={{ cursor: "pointer" }}
                                                        className={classnames({
                                                            active: customActiveTab === "4",
                                                        })}
                                                        onClick={() => {
                                                            toggleCustom("4");
                                                        }}
                                                    >
                                                        Fichier(s) attaché(s)
                                                    </NavLink>
                                                </NavItem>
                                        </Nav>

                                        <TabContent activeTab={customActiveTab} className="border border-top-0 p-4" id="nav-tabContent">
                                            <TabPane id="nav-speci" tabId="1">
                                                <div className="table-responsive">
                                                    <table className="table align-middle table-nowrap mb-0">
                                                        <thead>
                                                        <tr>
                                                            <th scope="col">Standard</th>
                                                            <th scope="col">Code immo</th>
                                                            <th scope="col">Référence</th>
                                                            <th scope="col">Etat</th>
                                                            <th scope="col">Action</th>
                                                        </tr>
                                                        </thead>

                                                        <tbody>
                                                        {list && list.attachments ? (
                                                            list.attachments.map((item, index) => (
                                                                <tr key={index}>
                                                                    <th scope="row">
                                                                        {item?.standard?.name}
                                                                    </th>
                                                                    <td>{item?.code_immo}</td>
                                                                    <td>{item?.reference}</td>
                                                                    <td>{item?.etat?.name}</td>
                                                                    <td>
                                                                        {userHavePermissions(["show-property", "edit-property"], "oneIn") && (
                                                                            <ul className="list-inline hstack gap-2 mb-0">
                                                                                {userHavePermissions(["show-property"]) && (
                                                                                    <li className="list-inline-item" title="Delete">
                                                                                        <Link className="remove-item-btn" to={`/inputs/${item.id}`}>
                                                                                            <i className="ri-eye-2-line align-bottom text-muted"></i>
                                                                                        </Link>
                                                                                    </li>
                                                                                )}

                                                                                {userHavePermissions(["edit-property"]) && (
                                                                                    <li className="list-inline-item" title="Edit">
                                                                                        <Link className="edit-item-btn" to={`/inputs/${item.id}/edit`}>
                                                                                            <i className="ri-pencil-fill align-bottom text-muted"></i>
                                                                                        </Link>
                                                                                    </li>
                                                                                )}
                                                                            </ul>
                                                                        )}
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        ) : null}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </TabPane>

                                            <TabPane id="nav-detail" tabId="2">
                                                <div className="table-responsive">
                                                    <table className="table mb-0">
                                                        <thead>
                                                        <tr>
                                                            <th scope="col">Date de transfert</th>
                                                            <th scope="col">Service ou Direction</th>
                                                            <th scope="col">Agent</th>
                                                            <th scope="col">Local</th>
                                                            <th scope="col">Action</th>
                                                        </tr>
                                                        </thead>

                                                        <tbody>
                                                        {list && list.assignments ? (
                                                            list.assignments.map((item, index) => (
                                                                <tr key={index}>
                                                                    <td>{formatDate(item.assignment_date)}</td>
                                                                    <td>{item?.service?.name || '--'}</td>
                                                                    <td>{item?.staff?.last_name} {item?.staff?.first_name}</td>
                                                                    <td>{item?.local?.name}</td>
                                                                    <td>
                                                                        {userHavePermissions(['edit-property-out', 'destroy-property-out'], "oneIn") &&(
                                                                            <ul className="list-inline hstack gap-2 mb-0">
                                                                                {userHavePermissions(['edit-property-out']) && (
                                                                                    <li className="list-inline-item" title="Edit">
                                                                                        <Link className="edit-item-btn" to="#" onClick={() => {
                                                                                            setEditData(item);
                                                                                            setShowTransferForm(true);
                                                                                        }}>
                                                                                            <i className="ri-pencil-fill align-bottom text-muted"></i>
                                                                                        </Link>
                                                                                    </li>
                                                                                )}

                                                                                {userHavePermissions(["destroy-property-out"]) && (
                                                                                    <li className="list-inline-item" title="Delete">
                                                                                        <Link className="remove-item-btn" onClick={() => deleteItem(item)} to="#">
                                                                                            <i className="ri-delete-bin-fill align-bottom text-muted"></i>
                                                                                        </Link>
                                                                                    </li>
                                                                                )}
                                                                            </ul>
                                                                        )}
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        ) : null}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </TabPane>

                                            <TabPane id="nav-detail" tabId="3">
                                                <div>
                                                    {typeof list === "object" && (
                                                        <>
                                                            {(list.origin === "Acquisition" || list.origin === "Production / Livraison à soi-même") && (
                                                                <>
                                                                    <div className="pt-3 border-top border-top-dashed mt-4">
                                                                        <Row>

                                                                            <Col lg={3} sm={6}>
                                                                                <div>
                                                                                    <p className="mb-2 text-uppercase fw-medium">Valeur Résiduel :</p>
                                                                                    <h5 className="fs-15 mb-0">{list.amortization_property?.residual_value}</h5>
                                                                                </div>
                                                                            </Col>
                                                                            <Col lg={3} sm={6}>
                                                                                <div>
                                                                                    <p className="mb-2 text-uppercase fw-medium">Fournisseur :</p>
                                                                                    <h5 className="fs-15 mb-0">{list.amortization_property?.supplier?.name}</h5>
                                                                                </div>
                                                                            </Col>
                                                                            <Col lg={3} sm={6}>
                                                                                <div>
                                                                                    <p className="mb-2 text-uppercase fw-medium">Numéro de facture :</p>
                                                                                    <div className="fs-15">{list.amortization_property?.invoice_number}</div>
                                                                                </div>
                                                                            </Col>
                                                                            <Col lg={3} sm={6}>
                                                                                <div>
                                                                                    <p className="mb-2 text-uppercase fw-medium">Numéro immobilier :</p>
                                                                                    <div className="fs-15">{list.amortization_property?.real_estate_number}</div>
                                                                                </div>
                                                                            </Col>
                                                                        </Row>
                                                                    </div>

                                                                    <div className="pt-3 border-top border-top-dashed mt-4">
                                                                        <Row>

                                                                            <Col lg={3} sm={6}>
                                                                                <div>
                                                                                    <p className="mb-2 text-uppercase fw-medium">Montant d'acquisition :</p>
                                                                                    <h5 className="fs-15 mb-0">{list.amortization_property?.acquisition_amount}</h5>
                                                                                </div>
                                                                            </Col>
                                                                        </Row>
                                                                    </div>
                                                                </>
                                                            )}

                                                            {list.origin === "Location" && (
                                                                <>
                                                                    <div className="pt-3 border-top border-top-dashed mt-4">
                                                                        <Row>

                                                                            <Col lg={3} sm={6}>
                                                                                <div>
                                                                                    <p className="mb-2 text-uppercase fw-medium">Valeur Résiduel :</p>
                                                                                    <h5 className="fs-15 mb-0">{list.amortization_property?.residual_value}</h5>
                                                                                </div>
                                                                            </Col>
                                                                            <Col lg={3} sm={6}>
                                                                                <div>
                                                                                    <p className="mb-2 text-uppercase fw-medium">Numéro de contrat :</p>
                                                                                    <h5 className="fs-15 mb-0">{list.amortization_property?.contract_number}</h5>
                                                                                </div>
                                                                            </Col>
                                                                            <Col lg={3} sm={6}>
                                                                                <div>
                                                                                    <p className="mb-2 text-uppercase fw-medium">Périodicité :</p>
                                                                                    <h5 className="fs-15 mb-0">{list.amortization_property?.period_of_rent}</h5>
                                                                                </div>
                                                                            </Col>
                                                                            <Col lg={3} sm={6}>
                                                                                <div>
                                                                                    <p className="mb-2 text-uppercase fw-medium">Nombre de période :</p>
                                                                                    <h5 className="fs-15 mb-0">{list.amortization_property?.number_of_period}</h5>
                                                                                </div>
                                                                            </Col>
                                                                        </Row>
                                                                    </div>

                                                                    <div className="pt-3 border-top border-top-dashed mt-4">
                                                                        <Row>

                                                                            <Col lg={3} sm={6}>
                                                                                <div>
                                                                                    <p className="mb-2 text-uppercase fw-medium">Date de début du contrat :</p>
                                                                                    <h5 className="fs-15 mb-0">{list.amortization_property?.contract_begin_date}</h5>
                                                                                </div>
                                                                            </Col>
                                                                            <Col lg={3} sm={6}>
                                                                                <div>
                                                                                    <p className="mb-2 text-uppercase fw-medium">Date de fin du contrat :</p>
                                                                                    <h5 className="fs-15 mb-0">{list.amortization_property?.contract_end_date}</h5>
                                                                                </div>
                                                                            </Col>
                                                                            <Col lg={3} sm={6}>
                                                                                <div>
                                                                                    <p className="mb-2 text-uppercase fw-medium">Montant du loyer :</p>
                                                                                    <h5 className="fs-15 mb-0">{list.amortization_property?.amount_of_rent}</h5>
                                                                                </div>
                                                                            </Col>
                                                                            <Col lg={3} sm={6}>
                                                                                <div>
                                                                                    <p className="mb-2 text-uppercase fw-medium">Status du contrat :</p>
                                                                                    <h5 className="fs-15 mb-0">{list.amortization_property?.contract_status}</h5>
                                                                                </div>
                                                                            </Col>
                                                                        </Row>
                                                                    </div>
                                                                </>
                                                            )}

                                                            {(list.origin === "Subvention 1" || list.orign === "Subvention 2" || list.origin === "Don") && (
                                                                <>
                                                                    <div className="pt-3 border-top border-top-dashed mt-4">
                                                                        <Row>
                                                                            <Col lg={3} sm={6}>
                                                                                <div>
                                                                                    <p className="mb-2 text-uppercase fw-medium">Valeur Résiduel :</p>
                                                                                    <h5 className="fs-15 mb-0">{list.amortization_property?.residual_value}</h5>
                                                                                </div>
                                                                            </Col>
                                                                            <Col lg={3} sm={6}>
                                                                                <div>
                                                                                    <p className="mb-2 text-uppercase fw-medium">Montant d'acquisition :</p>
                                                                                    <h5 className="fs-15 mb-0">{list.amortization_property?.acquisition_amount}</h5>
                                                                                </div>
                                                                            </Col>
                                                                            <Col lg={3} sm={6}>
                                                                                <div>
                                                                                    <p className="mb-2 text-uppercase fw-medium">Taux de financement :</p>
                                                                                    <h5 className="fs-15 mb-0">{list.amortization_property?.rate_of_funding}</h5>
                                                                                </div>
                                                                            </Col>
                                                                            <Col lg={3} sm={6}>
                                                                                <div>
                                                                                    <p className="mb-2 text-uppercase fw-medium">Donateur :</p>
                                                                                    <h5 className="fs-15 mb-0">{list.amortization_property?.donor_name}</h5>
                                                                                </div>
                                                                            </Col>
                                                                        </Row>
                                                                    </div>
                                                                </>
                                                            )}

                                                            {planArray}
                                                        </>
                                                    )}
                                                </div>
                                            </TabPane>
                                            <TabPane id="nav-detail" tabId="4">
                                                { fileTemplate }
                                            </TabPane>
                                        </TabContent>
                                    </div>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>

                <Col xl={3}>
                    <div className="sticky-side-div">
                        <Card className="border-bottom-dashed">
                            <CardHeader>
                                <h5 className="card-title mb-0">Actions</h5>
                            </CardHeader>

                            <CardBody>
                                <Row>
                                    <Col lg={6} style={{paddingRight: "3px"}}>
                                        <Button color="secondary" className="w-100 bg-gradient mb-2 btn-sm" onClick={() => setShowAttachForm(!showAttachForm)}>Attacher</Button>
                                    </Col>

                                    <Col lg={6} style={{paddingLeft: "3px"}}>
                                        <Button
                                            color="outline-secondary"
                                            className="w-100 mb-2 btn-sm"
                                            onClick={() => {
                                                if (attachments?.length) {
                                                    setShowDetachForm(!showDetachForm)
                                                }
                                            }}
                                        >
                                            Détacher
                                        </Button>
                                    </Col>

                                    {userHavePermissions(['store-transfert']) && (
                                        <Col lg={12}>
                                            <Button color="primary" className="w-100 bg-gradient btn-sm mb-2" onClick={() => setShowTransferForm(!showTransferForm)}>Transferer le bien</Button>
                                        </Col>
                                    )}

                                    {userHavePermissions(['store-property-out']) && (
                                        <Col lg={12}>
                                            <Button color="soft-danger" className="w-100 bg-gradient btn-sm mb-2" onClick={() => {
                                                setShowOutProperties(!showOutProperties)
                                            }}>Sortir le bien</Button>
                                        </Col>
                                    )}
                                    {userHavePermissions(['store-property-out']) && (
                                        <Col lg={12}>
                                            <Button color={'primary'}  className="w-100 bg-gradient btn-sm mb-2" disabled={submitting} onClick={() => handlePrint()}><PrinterLineIcon/> Imprimer</Button>
                                        </Col>
                                    )}
                                    {userHavePermissions(['store-property']) && (
                                        <Col lg={12}>
                                            <Button  color={'primary'} className="w-100 btn-sm mb-2" onClick={() => history.push(`/inputs/${id}/edit`)}><EditLineIcon/> Mettre à jour</Button>
                                        </Col>
                                    )}
                                </Row>
                            </CardBody>
                        </Card>
                    </div>
                </Col>
            </Row>

            <AttachmentForm
                idProperty={id}
                id="attach"
                title={"Attacher des biens"}
                data={createData}
                setData={setCreateData}
                show={showAttachForm}
                toggle={() => {setShowAttachForm(!showAttachForm)}}
                close={() => {setShowAttachForm(false)}}
                refreshData={items => {
                    setList({
                        ...list,
                        attachments: [...list.attachments, ...items]
                    })
                    const itemsIds = items.map(item => item.id);

                    // Add items Attachments
                    setAttachments([...attachments, ...items]);

                    // Remove items CreateData
                    setCreateData(createData.filter(item => !itemsIds.includes(item.id)));
                }}
                submitText={"Attacher"}
            />

            <AttachmentForm
                idProperty={id}
                id="dettach"
                title={"Détacher des biens"}
                data={attachments}
                setData={setAttachments}
                show={showDetachForm}
                toggle={() => {setShowDetachForm(!showDetachForm)}}
                close={() => {setShowDetachForm(false)}}
                refreshData={items => {
                    const itemsIds = items.map(item => item.id);

                    // Add items createData
                    setCreateData([...createData, ...items]);

                    // Remove items attachments
                    setAttachments(attachments.filter(item => !itemsIds.includes(item.id)));
                }}
                submitText={"Détacher"}
            />

            <TransferForm
                show={showTransferForm}
                toggle={() => {
                    if (!showTransferForm === false) {
                        setEditData(null);
                    }
                    setShowTransferForm(!showTransferForm);
                }}
                close={() => {
                    setShowTransferForm(false);
                    setEditData(null);
                }}
                onUpdateData={newEditData => {
                    setList({
                        ...list,
                        assignments: list.assignments.map(item => {
                            return item.id === editData.id ? newEditData : item
                        })
                    });
                }}
            />

            <OutPropertyForm
                show={showOutProperties}
                toggle={() => setShowOutProperties(!showOutProperties)}
                close={() => setShowOutProperties(false)}
            />
            <div style={{ display: "none" }}>
                <PropertyDetailsPrint
                    ref={componentRef}
                    list={list}
                    planArray = {planArray}
                    fileTemplate = {fileTemplate}
                    attachments={attachments}/>
            </div>
        </PageContent>
    );
};

export default DetailProperty;
