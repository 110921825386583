import React, {useCallback, useEffect, useMemo, useState} from "react";
import {toast} from "react-toastify";
import {Col, Row} from "reactstrap";
import DbDatatable from "../../Components/Common/DbDatatable";
import {CodeQR, Date, Ecart, Text} from "../../Components/Common/ColTableFormat";
import {useAxios} from "../../hooks";
import {GLOBAL_ERROR_MESSAGE} from "../../constants";
import ReactSelect from "../../Components/Common/ReactSelect";

const PropertiesNotInventoried = ({id}) => {
    const {axios} = useAxios();
    const [entities, setEntities] = useState(null);
    const [entitiesLoading, setEntitiesLoading] = useState(false);

    const [site, setSite] = useState(null);
    const [building, setBuilding] = useState(null);
    const [local, setLocal] = useState(null);
    const [staff, setStaff] = useState(null);
    const [loadFilterData, setLoadFilterData] = useState(false);
    const [filterData, setFilterData] = useState({
        sites: [],
        buildings: [],
        locals: [],
        staffs : []
    });
    const [users, setUsers] = useState([]);

    // Fetch Data
    const fetchData = useCallback((pageIndex = 1, pageSize = 10, search = "", other = null) => {
        if (id) {
            setEntitiesLoading(true);
            const newSite = site ? site.value : '';
            const newBuilding = building ? building.value : '';
            const newLocal = local ? local.value : '';
            const newStaff = staff ? staff.value : '';
            axios.get(`/api/property-not-inventoried?${`?inventory_id=${id}`}&page=${pageIndex}&per_page=${pageSize}&search=${search}&site_id=${newSite}&local_id=${newLocal}&staff_id=${newStaff}`)
                .then(({data}) => {
                    setEntities(data);
                })
                .catch(() => toast.error(GLOBAL_ERROR_MESSAGE, { position: "top-right", hideProgressBar: false, progress: undefined, toastId: "" }))
                .finally(() => setEntitiesLoading(false))
            ;
        }
    }, [building, id, local, site,staff]);

    const formatData = useCallback((data) => {
        return data.map(item => ({
            ...item,
            value: item.id,
            label: item.name
        }));
    }, []);
    useEffect(() => {
        setLoadFilterData(true);
        axios.get(`/api/property-inventories-ecrats`)
            .then(({data}) => {
                setFilterData({
                    sites: formatData(data.sites),
                    etats: formatData(data.etats),
                    buildings: [],
                    locals: [],
                })
                setUsers((data?.inventorists || []).map(item => ({
                    value: item.id,
                    label: `${item?.name || '-'} ${item?.last_name || '-'}`
                })));
            })
            .catch(() => {console.error("Something is wrong")})
            .finally(() => {setLoadFilterData(false)})
        ;
    }, []);
    useEffect(() => {
        fetchData();
    }, []);

    const handleSiteChange = (value) => {
        setBuilding(null);
        setLocal(null);
        setFilterData({
            sites: filterData.sites,
            buildings: [],
            locals: []
        });
        setSite(value);
        if (value) {
            setFilterData({
                sites: filterData.sites,
                buildings: formatData(value.batiments),
                locals: [],
            })
        }
    }
    const handleStaffChange = (value) => {
        setFilterData({
            sites: filterData.sites,
            buildings: filterData.buildings,
            etats: filterData.etats,
            locals: filterData.locals
        });
        setStaff(value);
        if (value) {
            setFilterData({
                sites: filterData.sites,
                buildings: filterData.buildings,
                locals: filterData.locals,
                etats: filterData.etats,
                staffs: users
            })
        }
    }

    const handleBuildingChange = (value) => {
        setLocal(null);
        setFilterData({
            sites: filterData.sites,
            buildings: filterData.buildings,
            locals: []
        });
        setBuilding(value);
        if (value) {
            setFilterData({
                sites: filterData.sites,
                buildings: filterData.buildings,
                locals: formatData(value.locals)
            })
        }
    }

    const columns = useMemo(() => {
        return [
            {
                Header: "Code QR",
                accessor: "qr_code.link",
                filterable: false,
                Cell: (cellProps) => {
                    return <CodeQR {...cellProps} />;
                },
            },
            {
                Header: "Codification",
                accessor: "codification",
                filterable: false,
                Cell: (cellProps) => {
                    return <Text {...cellProps} />;
                },
            },
            {
                Header: "Standard",
                accessor: "standard.name",
                filterable: false,
                Cell: (cellProps) => {
                    return <Text {...cellProps} />;
                },
            },
            {
                Header: "Code immo",
                accessor: "code_immo",
                filterable: false,
                Cell: (cellProps) => {
                    return <Text {...cellProps} />;
                },
            },
            {
                Header: "Etat du bien",
                accessor: "etat.name",
                filterable: false,
                Cell: (cellProps) => {
                    return <Text {...cellProps} />;
                },
            },
            {
                Header: "Local",
                accessor: "assignment_active.local.name",
                filterable: false,
                Cell: (cellProps) => {
                    return <Text {...cellProps} />;
                },
            },
        ]
    }, []);

    return (
        <>
            <Row className="mb-3">
                <Col sm={4}>
                    <ReactSelect
                        id="site"
                        name="site"
                        placeholder="Veuillez sélectionner le site"
                        value={site}
                        onChange={handleSiteChange}
                        options={filterData.sites}
                        isLoading={loadFilterData}
                    />
                </Col>

                <Col sm={4}>
                    <ReactSelect
                        id="building"
                        name="building"
                        placeholder="Veuillez sélectionner le batiment"
                        value={building}
                        onChange={handleBuildingChange}
                        options={filterData.buildings}
                        isLoading={loadFilterData}
                    />
                </Col>

                <Col sm={4}>
                    <ReactSelect
                        id="local"
                        name="local"
                        placeholder="Veuillez sélectionner le local"
                        value={local}
                        onChange={value => {setLocal(value)}}
                        options={filterData.locals}
                        isLoading={loadFilterData}
                    />
                </Col>

            </Row>
            <Row>
                <Col sm={4}>
                    <ReactSelect
                        id="staff"
                        name="staff"
                        placeholder="Filtrer par inventoriste"
                        value={staff}
                        onChange={handleStaffChange}
                        options={users}
                        isLoading={loadFilterData}
                    />
                </Col>
            </Row>

            <DbDatatable
                columns={columns}
                data={entities ? entities.data : []}
                className="custom-header-css"
                loading={entitiesLoading}
                fetchData={fetchData}
                perPage={entities ? entities['per_page'] : undefined}
                totalPage={entities ? entities['last_page'] : undefined}
            />
        </>
    )
};

export default PropertiesNotInventoried;