import {Col, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledDropdown} from "reactstrap";
import ReactSelect from "../../Components/Common/ReactSelect";
import DbDatatable from "../../Components/Common/DbDatatable";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {toast} from "react-toastify";
import {GLOBAL_ERROR_MESSAGE} from "../../constants";
import {CodeQR, Date, Ecart, Text} from "../../Components/Common/ColTableFormat";
import {useAxios} from "../../hooks";
import DifferenceForm from "./DifferenceForm";
import {userHavePermissions} from "../../helpers";

const PropertiesToInventory = ({id,inventory}) => {
    const {axios} = useAxios();
    const [entities, setEntities] = useState(null);
    const [entitiesLoading, setEntitiesLoading] = useState(false);
    const [property, setProperty] = useState(null);
    const [loadingCreate, setLoadingCreate] = useState(false);
    const [showDifferenceForm, setShowDifferenceForm]
        = useState(false);
    const [showInventoristeForm, setShowInventoristeForm] = useState(false);
    const [states, setStates] = useState([]);
    const [users, setUsers] = useState([]);
    const [locals, setLocals] = useState([]);

    const [site, setSite] = useState(null);
    const [building, setBuilding] = useState(null);
    const [local, setLocal] = useState(null);
    const [etat, setEtat] = useState(null);
    const [staff, setStaff] = useState(null);
    const [loadFilterData, setLoadFilterData] = useState(false);
    const [filterData, setFilterData] = useState({
        sites: [],
        buildings: [],
        locals: [],
        etats : [],
        staffs : []
    });

    // Fetch Data
    const fetchData = useCallback((pageIndex = 1, pageSize = 10, search = "", other = null) => {
        if (id) {
            setEntitiesLoading(true);
            const newSite = site ? site.value : '';
            const newEtat = etat ? etat.value : '';
            const newBuilding = building ? building.value : '';
            const newLocal = local ? local.value : '';
            const newStaff = staff ? staff.value : '';
            axios.get(`/api/property-inventories-ecrats/filters${`?inventory_id=${id}`}&page=${pageIndex}&per_page=${pageSize}&search=${search}&site_id=${newSite}&local_id=${newLocal}&etat_id=${newEtat}&staff_id=${newStaff}`)
                .then(({data}) => {
                    setEntities(data);
                })
                .catch(() => toast.error(GLOBAL_ERROR_MESSAGE, { position: "top-right", hideProgressBar: false, progress: undefined, toastId: "" }))
                .finally(() => setEntitiesLoading(false))
            ;
        }
    }, [building, id, local, site, etat,staff]);

    const formatData = useCallback((data) => {
        return data?.map(item => ({
            ...item,
            value: item.id,
            label: item.name
        }));
    }, []);

    useEffect(() => {
        setLoadFilterData(true);
        axios.get(`/api/property-inventories-ecrats`)
            .then(({data}) => {
                setFilterData({
                    sites: formatData(data.sites),
                    etats: formatData(data.etats),
                    buildings: [],
                    locals: [],
                })
                setUsers((data?.inventorists || []).map(item => ({
                    value: item.id,
                    label: `${item?.name || '-'} ${item?.last_name || '-'}`
                })));
            })
            .catch(() => {console.error("Something is wrong")})
            .finally(() => {setLoadFilterData(false)})
        ;
    }, []);
    useEffect(() => {
        fetchData();
    }, []);

    const handleSiteChange = (value) => {
        setBuilding(null);
        setLocal(null);
        setFilterData({
            sites: filterData.sites,
            etats: filterData.etats,
            buildings: [],
            locals: []
        });
        setSite(value);
        if (value) {
            setFilterData({...filterData,...{
                    sites: filterData.sites,
                    buildings: formatData(value.batiments),
                    locals: []
                }})
        }
    }

    const handleBuildingChange = (value) => {
        setLocal(null);
        setFilterData({
            sites: filterData.sites,
            buildings: filterData.buildings,
            etats: filterData.etats,
            locals: [],
            staffs : []
        });
        setBuilding(value);
        if (value) {
            setFilterData({...filterData,...{
                    sites: filterData.sites,
                    buildings: filterData.buildings,
                    locals: formatData(value.locals),
                    staffs: formatData(value.staffs),
                }})
        }
    }

    const handleEtatChange = (value) => {
        setFilterData({
            sites: filterData.sites,
            buildings: filterData.buildings,
            etats: filterData.etats,
            locals: filterData.locals
        });
        setEtat(value);
        if (value) {
            setFilterData({
                sites: filterData.sites,
                buildings: filterData.buildings,
                locals: filterData.locals,
                etats: filterData.etats
            })
        }
    }
   const handleStaffChange = (value) => {
        setFilterData({
            sites: filterData.sites,
            buildings: filterData.buildings,
            etats: filterData.etats,
            locals: filterData.locals
        });
        setStaff(value);
        if (value) {
            setFilterData({
                sites: filterData.sites,
                buildings: filterData.buildings,
                locals: filterData.locals,
                etats: filterData.etats,
                staffs: users
            })
        }
    }


    const columns = useMemo(() => {
        return [
            {
                Header: "Code QR",
                accessor: "property.qr_code.link",
                filterable: false,
                Cell: (cellProps) => {
                    return <CodeQR {...cellProps} />;
                },
            },
            {
                Header: "Codification",
                accessor: "property.codification",
                filterable: false,
                Cell: (cellProps) => {
                    return <Text {...cellProps} />;
                },
            },
            {
                Header: "Standard",
                accessor: "property.standard.name",
                filterable: false,
                Cell: (cellProps) => {
                    return <Text {...cellProps} />;
                },
            },
            {
                Header: "Code immo",
                accessor: "property.code_immo",
                filterable: false,
                Cell: (cellProps) => {
                    return <Text {...cellProps} />;
                },
            },
            {
                Header: "Etat du bien",
                accessor: "property.etat.name",
                filterable: false,
                Cell: (cellProps) => {
                    return <Text {...cellProps} />;
                },
            },
            {
                Header: "Local",
                accessor: "property.assignment_active.local.name",
                filterable: false,
                Cell: (cellProps) => {
                    return <Text {...cellProps} />;
                },
            },
            {
                Header: "Ecart ?",
                accessor: "ecart",
                filterable: false,
                Cell: (cellProps) => {
                    return <Ecart {...cellProps} />;
                },
            },
            {
                Header: "Observation",
                accessor: "observations",
                filterable: false,
                Cell: (cellProps) => {
                    return <Text {...cellProps} />;
                },
            },
         /*   {
                Header: "Statut",
                accessor: "status",
                filterable: false,
                Cell: (cellProps) => {
                    return (
                        <>
                            <div>{cellProps.value}</div>
                        </>
                    );
                },
            },*/
            {
                Header: "Actions",
                accessor: "property.is_editable",
                Cell: (cellProps) => {
                    const button = (
                        <DropdownToggle tag="a" className="btn btn-soft-secondary btn-sm">
                            <i className="ri-more-fill align-middle"/>
                        </DropdownToggle>
                    );

                    const detail = (
                        <li>
                            {
                                userHavePermissions(["validate-property-inventory"]) &&
                                <DropdownItem
                                    disabled={inventory.status === 'closed'}
                                    className="edit-item-btn"
                                    href="#showModal"
                                    data-bs-toggle="modal"
                                    onClick={() => {
                                        setShowDifferenceForm(true);
                                        setProperty(cellProps.row.original);
                                    }}>
                                    <i className="ri-git-branch-line align-bottom me-2 text-muted"/> Ecart
                                </DropdownItem>
                            }
                        </li>
                    );

                    const update = (
                        <li>
                           {/* <DropdownItem
                                disabled={true}
                                className="edit-item-btn"
                                href="#showModal"
                                data-bs-toggle="modal"
                                onClick={() => {
                                    setShowInventoristeForm(true);
                                    setProperty(cellProps.row.original);
                                }}
                            >
                                <i className="ri-user-settings-line align-bottom me-2 text-muted"/> Modifier
                            </DropdownItem>*/}
                            --
                        </li>
                    );

                    return cellProps.row.original.ecart && (
                        <UncontrolledDropdown>
                            {button}
                            <DropdownMenu className="dropdown-menu-end">
                                {cellProps.row.original.ecart ? detail : update}
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    );
                },
            },
        ]
    }, []);

    return (
        <>
            <Row className="mb-3">
                <Col sm={4}>
                    <ReactSelect
                        id="site"
                        name="site"
                        placeholder="Veuillez sélectionner le site"
                        value={site}
                        onChange={handleSiteChange}
                        options={filterData.sites}
                        isLoading={loadFilterData}
                    />
                </Col>

                <Col sm={4}>
                    <ReactSelect
                        id="building"
                        name="building"
                        placeholder="Veuillez sélectionner le batiment"
                        value={building}
                        onChange={handleBuildingChange}
                        options={filterData.buildings}
                        isLoading={loadFilterData}
                    />
                </Col>

                <Col sm={4}>
                    <ReactSelect
                        id="local"
                        name="local"
                        placeholder="Veuillez sélectionner le local"
                        value={local}
                        onChange={value => {setLocal(value)}}
                        options={filterData.locals}
                        isLoading={loadFilterData}
                    />
                </Col>
            </Row>
            <Row className={"mb-2"}>

                <Col sm={4}>
                    <ReactSelect
                        id="etat"
                        name="etat"
                        placeholder="Filtrer par états"
                        value={etat}
                        onChange={handleEtatChange}
                        options={filterData.etats}
                        isLoading={loadFilterData}
                    />
                </Col>
                <Col sm={4}>
                    <ReactSelect
                        id="staff"
                        name="staff"
                        placeholder="Filtrer par inventoriste"
                        value={staff}
                        onChange={handleStaffChange}
                        options={users}
                        isLoading={loadFilterData}
                    />
                </Col>
            </Row>

            <DbDatatable
                columns={columns}
                data={entities ? entities.data : []}
                className="custom-header-css"
                loading={entitiesLoading}
                fetchData={fetchData}
                perPage={entities ? entities['per_page'] : undefined}
                totalPage={entities ? entities['last_page'] : undefined}
            />

            <DifferenceForm
                open={showDifferenceForm}
                toggle={() => {
                    if (!!showDifferenceForm) {
                        setProperty(null);
                    }
                    setShowDifferenceForm(!showDifferenceForm)
                }}
                property={property}
                loading={loadingCreate}
                users={users}
                locals={locals}
                states={states}
            />

        </>
    )
};

export default PropertiesToInventory;