import React, {useCallback, useEffect, useMemo, useState} from "react";
import {
    Alert, Badge,
    Button,
    Card,
    CardBody,
    Col, Modal, ModalBody, ModalFooter, ModalHeader, Nav, NavItem, NavLink,
    Row, Spinner, TabContent, TabPane,
} from "reactstrap";
import {useHistory, useParams} from "react-router-dom";
import CountUp from "react-countup";
import env from "../../helpers/env";
import PageContent from "../../Layouts/PageContent";
import {useAxios, useFetchDataList} from "../../hooks";
import classnames from "classnames";
import PropertiesToInventory from "./PropertiesToInventory";
import NewPropertiesFound from "./NewPropertiesFound";
import PropertiesNotInventoried from "./PropertiesNotInventoried";
import {downloadReport, userHavePermissions} from "../../helpers";
import CloseInventoryForm from "./CloseInventoryForm";
import {toast} from "react-toastify";
import InventoristForm from "./InventoristForm";
import InventoristList from "./InventoristList";

const PropertiesInventory = () => {
    document.title ="Biens | "+env('NAME');
    const {axios} = useAxios();
    const [statistics, setStatistics] = useState({
        "totalProperties": 25,
        "totalInventoriedProperties": 8,
        "totalRemain": 17,
        "totalSiteInventoried": 0,
        "totalSiteRemain": 0,
        "totalBureauInventoried": 0,
        "totalBureauRemain": 0,
        "totalLocalInventoried": 0,
        "totalLocalRemain": 0
    })
    const [activeTab, setactiveTab] = useState("1");
    const [inventory, setInventory] = useState();
    const [showCloseInvForm, setShowCloseInvForm] = useState(false);
    const [unInventoriedProperties, setUnInventoriedProperties] = useState();
    const [confirmationModal,setConfirmationModal] = useState(false)
    const [invAction,setInvAction] = useState('')
    const [closingInv, setClosingInv] = useState(false);
    const [inventoristFormModal, setInventoristFormModal] = useState(false);
    const [inventoristListModal, setInventoristListModal] = useState(false);
    const [inventoristData, setInventoristData] = useState(null);
    const [localsInventoried, setLocalsInventoried] = useState(null);
    const [submitting,setSubmitting] = useState(null)
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const [showRemainingLocals, setShowRemainingLocals] = useState(false);
    const location = useHistory();
    const toggleToolTip = () => setTooltipOpen(!tooltipOpen);
    const toggle = (tab) => {
        if (activeTab !== tab) {
            setactiveTab(tab);
        }
    };
    const {id} = useParams()
    useEffect(() => {
        axios.get(`/api/property-inventories-stats?inventory_id=${id}`)
            .then(({data}) => {
                setStatistics(data)
                setLocalsInventoried(data.locals_inventoried)
            }).catch(() => {console.log("Something is wrong")});
        axios.get(`/api/inventorist-locals/create?inventory_id=${id}`)
            .then(({data}) => {
                setInventoristData(data)
            }).catch(() => {console.log("Something is wrong")});

        getInventory();
    }, [id]);
    const getInventory = () => {
        axios.get(`/api/inventories-show/${id}`)
            .then(({data}) => {
            setInventory(data)
            })
            .catch(() => {
                console.log("Something is wrong")});
    }
    const closeInventory = () => {
        setClosingInv(true)
        axios.get(`/api/closing-inventories/${id}`)
            .then(({data}) => {
                if (data.remain_properties){
                    setShowCloseInvForm(true)
                    setUnInventoriedProperties(data)
                }else{
                    proceedClosing()
                }
            })
            .catch(() => {

            })
            .finally(() => {setConfirmationModal(false); setClosingInv(false)});
    };
    const proceedClosing = () => {
        axios.post(`/api/closing-inventories/${id}`)
            .then(({data}) => {
                toast.success("Inventaire clôturé avec succès !")
                setClosingInv(false)
                //location.reload()
                getInventory()
            })
            .catch((error) => {

                switch (error.response.status)
                {
                    case 422 :
                        setConfirmationModal(false)
                        toast.error(error.response.data.error)
                        break;
                    case 500:
                        toast.error("Oups! Quelque chose s'est mal passée")
                        break;
                }            })
            .finally(() => {setClosingInv(false)});

    };
    const openInventory = () => {
        axios.post(`/api/reopen-inventories/${id}`)
            .then(({data}) => {
                toast.success("Inventaire réouvert avec succès !")
                setClosingInv(false)
                showConfirmDialog(false)
                getInventory()
                //location.reload()
            })
            .catch((error) => {
                switch (error.response.status)
                {
                    case 422 :
                        setConfirmationModal(false)
                        toast.error(error.response.data.error)
                        break;
                    case 500:
                        toast.error("Oups! Quelque chose s'est mal passée")
                        break;
                }
            })
            .finally(() => {setClosingInv(false)});

    };
    const showConfirmDialog = (isOpen,action) =>{
        setConfirmationModal(isOpen)
        setInvAction(action)
    }
    const editInventorist = (item) => {
        console.log({item})
    }

    const toggleInvForm = () => {
        if (inventoristFormModal)
        {
            getInventory()
        }
        setInventoristFormModal(!inventoristFormModal)
    }
    const remainingLocals = useFetchDataList(`/api/inventories/configs/${id}`, (data) => {
        return {
            remainingLocals: data.remainingLocals,
            inventory : data.inventory
        };
    });

    const exportRemainingLocals = () => {
        setSubmitting(true)
        axios.get(`/api/exports/remaining-locals/${id}`,
            {
                method: 'GET',
                responseType: 'blob'
            })
            .then(({data}) => {
                downloadReport(data,'liste-bureaux-restants','xlsx')
                toast("Exportation effectuée avec succès", {type: 'success'});
            })
            .catch(error => {
                toast( "Echec d'exportation", {type: 'error'});

            })
            .finally(() => setSubmitting(false))
        ;
    }


    return (
        <PageContent title={"Détails inventaire"} pageTitle={"Inventaires"}>
            <div  className="d-flex justify-content-center mb-2">
                {
                    remainingLocals?.list?.inventory?.id && remainingLocals?.list?.remainingLocals?.length ? (
                        <>
                            <div className={"bg-soft-danger rounded p-2 w-100"}>
                                <div className={'text-danger mb-2 fw-bold'}>
                                    Certains locaux ne sont pas encore assignés pour cet inventaire

                                    <Button className={"float-end"} id={"export-btn"} color={'transparent'} onClick={exportRemainingLocals}>
                                        <a href="#">Exporter</a>
                                    </Button>
                                    <Button className={"float-end"} id={"export-btn"} color={'transparent'} onClick={() => setShowRemainingLocals(!showRemainingLocals)}>
                                        {
                                            showRemainingLocals ? <a href={"#"}>Reduire</a> : <a href={"#"}>Afficher</a>
                                        }
                                    </Button>
                                </div>
                                {
                                    showRemainingLocals && (
                                        <>
                                            <table className={"table table-striped"}>
                                                <thead>
                                                <tr>
                                                    <th>Site</th>
                                                    <th>Bâtiment</th>
                                                    <th>Bureaux</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {
                                                    remainingLocals.list?.remainingLocals.map((batiment) => (
                                                        <tr key={batiment.id}>
                                                            <td>{ batiment.site.name }</td>
                                                            <td>{ batiment?.name }</td>
                                                            <td>
                                                                {
                                                                    batiment.locals.map((local) => (
                                                                        <Badge color={'dark'} key={local.id} md={3} className={'text-white m-1'}>{local?.name}</Badge>
                                                                    ))
                                                                }
                                                            </td>
                                                        </tr>
                                                    ))
                                                }
                                                </tbody>
                                            </table>
                                        </>
                                    )

                                }

                            </div>
                        </>
                    ) :
                        <div className={'alert alert-info mb-4 w-100    '}>
                            Tous les locaux sont déjà assignés pour cet inventaire
                        </div>
                }
            </div>
            <div  className="d-flex justify-content-end mb-2">

                { inventory && (inventory?.inventory?.status !=='closed' || inventory?.inventory?.reopen_at !== null) && userHavePermissions(["closing-an-inventory"]) ? (
               <span>
                   {
                       userHavePermissions(["store-inventory"]) &&
                       <>
                           <Button color={'primary'} onClick={() => location.push(`/inventory-config/${inventory?.inventory?.id}`) } className="btn-sm btn-primary">
                               Ajouter un inventoriste
                           </Button>&nbsp;
                           <Button color={'primary'} onClick={() => setInventoristListModal(true) }  className="btn-sm btn-primary" disabled={closingInv}>
                               Liste des inventoristes
                           </Button>&nbsp;
                       </>
                   }
                   {
                       userHavePermissions(["closing-an-inventory"]) &&
                       <Button color={'primary'} onClick={() => showConfirmDialog(true,'close') } className="btn-sm btn-primary" disabled={closingInv}>
                           Clôturer cet inventaire
                       </Button>
                   }

                </span>
            ) :
                    <>
                        { userHavePermissions(['reopen-an-inventory']) &&
                            <Button color={'primary'} onClick={() => showConfirmDialog(true,'open') } className="btn-sm btn-primary" disabled={closingInv}>
                                Réouvrir cet inventaire
                            </Button>
                        }
                    </>
           }
            </div>

            {
                inventory && inventory?.status === 'closed' && (
                    <Alert color={"primary text-center"}>Inventaire cloturé le {inventory?.closed_at} par <strong>{inventory?.closed_by?.name}</strong></Alert>
                )
            }
            {
                inventory && inventory?.reopen_at && (
                    <Alert color={"warning text-center"}>Inventaire réouvert le {inventory?.reopen_at}</Alert>
                )
            }
            <Row>
                <Col xxl={3} sm={6}>
                    <Card className="card-animate">
                        <CardBody>
                            <div className="d-flex justify-content-between">
                                <div>
                                    <p className="fw-medium text-muted mb-0">Total biens inventoriés</p>
                                    <h2 className="mt-4 ff-secondary fw-semibold">
                                        <span className="counter-value">
                                            <CountUp
                                                start={0}
                                                end={statistics?.totalInventoriedProperties}
                                                decimal={"0"}
                                                duration={3}
                                            />
                                        </span>
                                    </h2>
                                    <p className="mb-0 text-muted">
                                        <span className={"badge bg-light mb-0 text-danger"} style={{fontSize: "0.8rem"}}>
                                            {statistics?.totalRemain}
                                        </span>bien(s) restant(s)
                                    </p>
                                </div>
                                <div>
                                    <div className="avatar-sm flex-shrink-0">
                                        <span className={"avatar-title rounded-circle fs-4 bg-soft-info text-info"}>
                                            <i className="ri-honour-line"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
                <Col xxl={3} sm={6}>
                    <Card className="card-animate">
                        <CardBody>
                            <div className="d-flex justify-content-between">
                                <div>
                                    <p className="fw-medium text-muted mb-0">Total sites inventoriés</p>
                                    <h2 className="mt-4 ff-secondary fw-semibold">
                                        <span className="counter-value">
                                            <CountUp
                                                start={0}
                                                end={statistics.totalSiteInventoried}
                                                decimal={"0"}
                                                duration={3}
                                            />
                                        </span>
                                    </h2>
                                    <p className="mb-0 text-muted">
                                        <span className={"badge bg-light mb-0 text-danger"} style={{fontSize: "0.8rem"}}>
                                        {statistics?.totalSiteRemain}
                                    </span>site(s) restant(s)</p>
                                </div>
                                <div>
                                    <div className="avatar-sm flex-shrink-0">
                                        <span className={"avatar-title rounded-circle fs-4 bg-soft-info text-info"}>
                                            <i className="ri-building-2-line"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
                <Col xxl={3} sm={6}>
                    <Card className="card-animate">
                        <CardBody>
                            <div className="d-flex justify-content-between">
                                <div>
                                    <p className="fw-medium text-muted mb-0">Total bâtiments inventoriés</p>
                                    <h2 className="mt-4 ff-secondary fw-semibold">
                                        <span className="counter-value">
                                            <CountUp
                                                start={0}
                                                end={statistics.totalBatimentInventoried}
                                                decimal={""}
                                                duration={3}
                                            />
                                        </span>
                                    </h2>
                                    <p className="mb-0 text-muted">
                                        <span className={"badge bg-light mb-0 text-danger"} style={{fontSize: "0.8rem"}}>
                                        {statistics?.totalBatimentRemain}
                                    </span>bâtiment(s) restant(s)</p>
                                </div>
                                <div>
                                    <div className="avatar-sm flex-shrink-0">
                                        <span className={"avatar-title rounded-circle fs-4 bg-soft-info text-info"}>
                                            <i className="ri-luggage-deposit-line"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
                <Col xxl={3} sm={6}>
                    <Card className="card-animate">
                        <CardBody>
                            <div className="d-flex justify-content-between">
                                <div>
                                    <p className="fw-medium text-muted mb-0">Total bureaux inventoriés</p>
                                    <h2 className="mt-4 ff-secondary fw-semibold">
                                        <span className="counter-value">
                                            <CountUp
                                                start={0}
                                                end={statistics.totalLocalInventoried?.toString()}
                                                decimal={1}
                                                // suffix={"K"}
                                                duration={3}
                                            />
                                        </span>
                                    </h2>
                                    <p className="mb-0 text-muted">
                                        <span className={"badge bg-light mb-0 text-danger"} style={{fontSize: "0.8rem"}}>
                                        {statistics?.totalLocalRemain}
                                    </span>bureau(x) restant(s)</p>
                                </div>
                                <div>
                                    <div className="avatar-sm flex-shrink-0">
                                        <span className={"avatar-title rounded-circle fs-4 bg-soft-info text-info"}>
                                            <i className="ri-home-gear-line"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>


            {
                inventory && (
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <Nav tabs className="nav-tabs nav-tabs-custom mb-3">
                                    <NavItem>
                                        <NavLink style={{ cursor: "pointer" }} className={classnames({ active: activeTab === "1", })} onClick={() => { toggle("1"); }} >
                                            Liste des biens inventoriés
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink style={{ cursor: "pointer" }} className={classnames({ active: activeTab === "2", })} onClick={() => { toggle("2"); }} >
                                            Liste des nouveaux biens retrouvés
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink style={{ cursor: "pointer" }} className={classnames({ active: activeTab === "3", })} onClick={() => { toggle("3"); }} >
                                            Liste des biens non inventoriés
                                        </NavLink>
                                    </NavItem>
                                </Nav>

                                <CardBody>
                                    <TabContent activeTab={activeTab} className="text-muted">
                                        <TabPane tabId="1" id="home">
                                            <PropertiesToInventory id={id} inventory={inventory}/>
                                        </TabPane>

                                        <TabPane tabId="2" id="product">
                                            <NewPropertiesFound id={id} inventory={inventory}/>
                                        </TabPane>

                                        <TabPane tabId="3" id="messages">
                                            <PropertiesNotInventoried id={id} inventory={inventory}/>
                                        </TabPane>
                                    </TabContent>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                )
            }

            <CloseInventoryForm
                toggle={() => setShowCloseInvForm(!showCloseInvForm) }
                open={showCloseInvForm}
                proceedClosing={proceedClosing}
                properties={unInventoriedProperties}
            />
            <InventoristForm
                toggle={() => toggleInvForm() }
                inventory={inventory?.inventory}
                refresh={() => getInventory()}
                inventoristData={inventoristData}
                open={inventoristFormModal}
            />
            {
                inventory && (
                    <InventoristList
                        toggle={() => setInventoristListModal(!inventoristListModal) }
                        inventory={inventory?.inventory}
                        localsInventoried={localsInventoried}
                        refresh={() => getInventory()}
                        inventoristData={inventoristData}
                        open={inventoristListModal}
                    />
                )
            }
            <Modal id="ConfirmationModal" isOpen={confirmationModal}  size="md" className="border-0" modalClassName="zoomIn">
                <ModalHeader toggle={() => setConfirmationModal(!confirmationModal) } className="p-3 bg-soft-info">
                    Confirmer la {invAction === 'open' ? 'réouverture' : 'clôture' }
                </ModalHeader>
                <ModalBody>
                    <Row className="g-3 text-center">
                        Êtes vous sûre de vouloir vraiment {invAction === 'open' ? 'réouvrir' : 'clôturer' } cet inventaire?
                    </Row>
                </ModalBody>

                <ModalFooter>
                    <Button color="light" onClick={() => setConfirmationModal(false)}>
                        Annuler
                    </Button>

                    <button type="submit" className="btn btn-success" id="add-btn" onClick={invAction !== 'open'? closeInventory : openInventory}>
                        {closingInv && (
                            <Spinner size="sm" className="flex-shrink-0 ml-2"> Traitement en cours... </Spinner>
                        )}
                        <i className="ri-close-line align-bottom"></i> {closingInv ? "Clôture de l'inventaire en cours..." :"Oui, continuer"}
                    </button>
                </ModalFooter>
            </Modal>
        </PageContent>
    );
};

export default PropertiesInventory;