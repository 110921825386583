import {
    Button,
    Col,
    FormFeedback,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
    Spinner
} from "reactstrap";
import React, {useState} from "react";
import {useParams} from "react-router-dom";
import {toast} from "react-toastify";
import ReactSelect from "../../Components/Common/ReactSelect";
import {useAxios, useFetchDataList} from "../../hooks";
import {removeNullValue} from "../../helpers";

const OutPropertyForm = ({show, toggle, close}) => {
    const {axios} = useAxios();
    const {id} = useParams();

    const [submitting, setSubmitting] = useState(false);
    const defaultError = {
        property_id : [],
        motif : [],
        output_date : [],
        tva : [],
        transaction_amount: [],
        buyer_name: []
    };
    const [errorMessage, setErrorMessage] = useState(defaultError);
    const [motif, setMotif] = useState(null);
    const [outputDate, setOutputDate] = useState("");
    const [transactionAmount, setTransactionAmount] = useState("");
    const [tva, setTva] = useState("");
    const [buyerName, setBuyerName] = useState("");
    const [confirmProcessing, setConfirmation] = useState(false);

    const {list, loading} = useFetchDataList(`/api/properties/outputs/create`, data => {
        return {
            motifs: data.motifs.map(item => {
                return {
                    value: item,
                    label: item
                };
            }),
        };
    });

    const submit = () => {
        const sendData = {
            property_id : id,
            motif : motif ? motif.value : "",
            output_date : outputDate,
            transaction_amount : transactionAmount,
            tva : tva,
            buyer_name: buyerName
        };

        if (motif && motif.value !== "Vente") {
            delete sendData.buyer_name;
            delete sendData.transaction_amount;
            delete sendData.tva;
        }

        setSubmitting(true);
        axios.post(`/api/properties/${id}/outputs`, removeNullValue(sendData))
            .then(response => {
                setErrorMessage(defaultError);
                toast.success("Succès de l'opération");
                toggle()
                setConfirmation(false)
            })
            .catch(error => {
                toast.error("Echec de l'opération")
                if (error.response.status === 422) {
                    setErrorMessage({...defaultError, ...error.response.data.error});
                }
            })
            .finally(() => setSubmitting(false))
        ;
    };

    return (
        <>
            <Modal
                size={"md"}
                id="out-form"
                isOpen={show}
                toggle={toggle}
            >
                <ModalHeader toggle={toggle} className="p-3 bg-soft-info">
                    Sortir le bien
                </ModalHeader>

                <ModalBody>
                    <Row>
                        <Col lg={12} className="mb-3">
                            <div id="modal-id">
                                <Label htmlFor="motif" className="form-label">Motif</Label>
                                <ReactSelect
                                    id="motif"
                                    name="motif"
                                    placeholder="Veuillez sélectionner le motif"
                                    value={motif}
                                    onChange={value => setMotif(value)}
                                    options={list ? list.motifs : []}
                                    isLoading={loading}
                                    error={errorMessage.motif}
                                />
                            </div>
                        </Col>

                        <Col lg={12} className="mb-3">
                            <div id="modal-id">
                                <Label htmlFor="output_date" className="form-label">Date de sortie</Label>
                                <Input
                                    type="date"
                                    className="form-control"
                                    id="output_date"
                                    placeholder="Veuillez entrer la date de sortie"
                                    value={outputDate}
                                    validate={{
                                        required: { value: errorMessage.output_date.length > 0 },
                                    }}
                                    onChange={e => setOutputDate(e.target.value)}
                                    invalid={errorMessage.output_date.length > 0}
                                />
                                {errorMessage.output_date.length > 0 && (
                                    <FormFeedback type="invalid">
                                        {errorMessage.output_date[0]}
                                    </FormFeedback>
                                ) }
                            </div>
                        </Col>

                        {motif !== null && motif.value === "Vente" && (
                            <>
                                <Col lg={12} className="mb-3">
                                    <div id="modal-id">
                                        <Label htmlFor="buyerName" className="form-label">Nom de l'écheteur</Label>

                                        <Input
                                            type="text"
                                            className="form-control"
                                            id="buyerName"
                                            placeholder="Veuillez entrer le nom de l'acheteur"
                                            value={buyerName}
                                            validate={{
                                                required: { value: errorMessage.buyer_name.length > 0 },
                                            }}
                                            onChange={e => setBuyerName(e.target.value)}
                                            invalid={errorMessage.buyer_name.length > 0}
                                        />

                                        {errorMessage.buyer_name.length > 0 && (
                                            <FormFeedback type="invalid">
                                                {errorMessage.buyer_name[0]}
                                            </FormFeedback>
                                        ) }
                                    </div>
                                </Col>

                                <Col lg={12} className="mb-3">
                                    <div id="modal-id">
                                        <Label htmlFor="amount_transaction" className="form-label">Montant de transation</Label>

                                        <Input
                                            type="number"
                                            className="form-control"
                                            id="transaction_amount"
                                            placeholder="Veuillez entrer le montant de la transation"
                                            value={transactionAmount}
                                            min={0}
                                            validate={{
                                                required: { value: errorMessage.transaction_amount.length > 0 },
                                            }}
                                            onChange={e => setTransactionAmount(e.target.value)}
                                            invalid={errorMessage.transaction_amount.length > 0}
                                        />

                                        {errorMessage.transaction_amount.length > 0 && (
                                            <FormFeedback type="invalid">
                                                {errorMessage.transaction_amount[0]}
                                            </FormFeedback>
                                        ) }
                                    </div>
                                </Col>

                                <Col lg={12}>
                                    <div id="modal-id">
                                        <Label htmlFor="tva" className="form-label">TVA</Label>

                                        <Input
                                            type="number"
                                            className="form-control"
                                            id="tva"
                                            placeholder="Veuillez entrer la TVA"
                                            min={0}
                                            value={tva}
                                            validate={{
                                                required: { value: errorMessage.tva.length > 0 },
                                            }}
                                            onChange={e => setTva(e.target.value)}
                                            invalid={errorMessage.tva.length > 0}
                                        />

                                        {errorMessage.tva.length > 0 && (
                                            <FormFeedback type="invalid">
                                                {errorMessage.tva[0]}
                                            </FormFeedback>
                                        ) }
                                    </div>
                                </Col>
                            </>
                        )}
                    </Row>
                </ModalBody>

                <ModalFooter>
                    <Button type="button" color="light" onClick={close}>Annuler</Button>
                    <Button type="button" color="primary" onClick={() => { setConfirmation(true)}}>
                        {submitting && (
                            <Spinner size="sm" className="flex-shrink-0 ml-2"> Loading... </Spinner>
                        )}
                        {" "}
                        Sortir
                    </Button>
                </ModalFooter>
            </Modal>

            <Modal id="confirmationDialog" isOpen={confirmProcessing}  toggle={toggle} size="md" className="border-0" modalClassName="zoomIn">
                <ModalHeader toggle={toggle} className="p-3 bg-soft-info">
                    Confirmation de sortie
                </ModalHeader>
                <ModalBody>
                    <Row className="d-flex justify-content-center mt-4 text-danger">
                        Êtes vous sûr(e) de vouloir sortir ce bien ?
                    </Row>
                </ModalBody>

                <ModalFooter>
                    <Button color="light" onClick={() => { setConfirmation(false)}}>
                        Annuler
                    </Button>

                    <button type="submit" className="btn btn-success" id="add-btn" onClick={submit}>
                    <span className="d-flex align-items-center justify-content-center">
                        {submitting && (
                            <Spinner size="sm" className="flex-shrink-0 ml-2"> Traitement en cours... </Spinner>
                        )}
                        <span className="ms-2">{submitting ? "Chargement" : "Continuer"}</span>
                    </span>
                    </button>
                </ModalFooter>
            </Modal>

        </>


    );
};

export default OutPropertyForm;