import React, {useCallback, useEffect, useMemo, useState} from "react";
import {toast} from "react-toastify";
import {
    Button,
    Col,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Modal, ModalBody, ModalFooter,
    ModalHeader,
    Row, Spinner,
    UncontrolledDropdown
} from "reactstrap";
import DbDatatable from "../../Components/Common/DbDatatable";
import {CodeQR, Date, Ecart, FullName, Text} from "../../Components/Common/ColTableFormat";
import {useAxios} from "../../hooks";
import {GLOBAL_ERROR_MESSAGE} from "../../constants";
import ReactSelect from "../../Components/Common/ReactSelect";
import {Routes, Route, useHistory} from 'react-router-dom';

const NewPropertiesFound = ({id, props, inventory}) => {
    const {axios} = useAxios();
    const navigate = useHistory();

    const [entities, setEntities] = useState(null);
    const [entitiesLoading, setEntitiesLoading] = useState(false);
    const [site, setSite] = useState(null);
    const [building, setBuilding] = useState(null);
    const [local, setLocal] = useState(null);
    const [staff, setStaff] = useState(null);
    const [users, setUsers] = useState([]);
    const [loadFilterData, setLoadFilterData] = useState(false);
    const [imageViewerModal, setImageViewerModal] = useState(false);
    const [currentImage, setCurrentImage] = useState(null);
    const [filterData, setFilterData] = useState({
        sites: [],
        buildings: [],
        locals: [],
        staffs : []
    });

    // Fetch Data
    const fetchData = useCallback((pageIndex = 1, pageSize = 10, search = "", other = null) => {
        if (id) {
            setEntitiesLoading(true);
            const newSite = site ? site.value : '';
            const newLocal = local ? local.value : '';
            const newStaff = staff ? staff.value : '';
            axios.get(`/api/property-not-found?inventory_id=${id}&page=${pageIndex}&per_page=${pageSize}&search=${search}&site_id=${newSite}&local_id=${newLocal}&staff_id=${newStaff}`)
                .then(({data}) => {
                    setEntities(data);
                })
                .catch(() => toast.error(GLOBAL_ERROR_MESSAGE, { position: "top-right", hideProgressBar: false, progress: undefined, toastId: "" }))
                .finally(() => setEntitiesLoading(false))
            ;
        }
    }, [building, id, local, site,staff]);
    const formatData = useCallback((data) => {
        return data.map(item => ({
            ...item,
            value: item.id,
            label: item.name
        }));
    }, []);

    useEffect(() => {
        setLoadFilterData(true);
        axios.get(`/api/property-inventories-ecrats`)
            .then(({data}) => {
                setFilterData({
                    sites: formatData(data.sites),
                    etats: formatData(data.etats),
                    buildings: [],
                    locals: [],
                })
                setUsers((data?.inventorists || []).map(item => ({
                    value: item.id,
                    label: `${item?.name || '-'} ${item?.last_name || '-'}`
                })));
            })
            .catch(() => {console.error("Something is wrong")})
            .finally(() => {setLoadFilterData(false)})
        ;
    }, []);
    useEffect(() => {
        fetchData();
    }, []);

    const handleSiteChange = (value) => {
        setBuilding(null);
        setLocal(null);
        setFilterData({
            sites: filterData.sites,
            buildings: [],
            locals: []
        });
        setSite(value);
        if (value) {
            setFilterData({
                sites: filterData.sites,
                buildings: formatData(value.batiments),
                locals: []
            })
        }
    }

    const handleBuildingChange = (value) => {
        setLocal(null);
        setFilterData({
            sites: filterData.sites,
            buildings: filterData.buildings,
            locals: []
        });
        setBuilding(value);
        if (value) {
            setFilterData({
                sites: filterData.sites,
                buildings: filterData.buildings,
                locals: formatData(value.locals)
            })
        }
    }

    const handleStaffChange = (value) => {
        setFilterData({
            sites: filterData.sites,
            buildings: filterData.buildings,
            etats: filterData.etats,
            locals: filterData.locals
        });
        setStaff(value);
        if (value) {
            setFilterData({
                sites: filterData.sites,
                buildings: filterData.buildings,
                locals: filterData.locals,
                etats: filterData.etats,
                staffs: users
            })
        }
    }


    const createProperty = (newPropertyId) => {
        navigate.push(`/inputs/create?property_id=${newPropertyId}`)
    }

    const columns = useMemo(() => {
        return [

            {
                Header: "Image",
                accessor: "image",
                filterable: false,
                Cell: (cellProps) => {
                    return (
                        <>
                            <a href="#" onClick={() => {setCurrentImage(cellProps.row.original.image); setImageViewerModal(true)}}>
                                <img src={cellProps.row.original.image} alt="" width={50} height={50} className="img-fluid rounded" />
                            </a>
                        </>
                    );
                },
            },
            {
                Header: "Bureau",
                accessor: "local.name",
                filterable: false,
                Cell: (cellProps) => {
                    return <Text {...cellProps} />;
                },
            },
            {
                Header: "Numéro Provisoire",
                accessor: "temp_num",
                filterable: false,
                Cell: (cellProps) => {
                    return <Text {...cellProps} />;
                },
            },
            {
                Header: "Standard",
                accessor: "standard.name",
                filterable: false,
                Cell: (cellProps) => {
                    return <Text {...cellProps} />;
                },
            },
            {
                Header: "Utilisateur",
                accessor: "staff",
                filterable: false,
                Cell: (cellProps) => {
                    return <FullName {...cellProps} />;
                },
            },
            {
                Header: "Etat du bien",
                accessor: "etat.name",
                filterable: false,
                Cell: (cellProps) => {
                    return <Text {...cellProps} />;
                    },
            },
            {
                Header: "Observation",
                accessor: "observations",
                filterable: false,
                Cell: (cellProps) => {
                    return <Text {...cellProps} />;
                    },
            },
            {
                Header: "Actions",
                accessor: "property.is_editable",
                Cell: (cellProps) => {
                    const button = (
                        <DropdownToggle tag="a" className="btn btn-soft-secondary btn-sm">
                            <i className="ri-more-fill align-middle"/>
                        </DropdownToggle>
                    );

                    const save = (
                        <li>
                            <DropdownItem
                                disabled={cellProps.row.original.property_id != null}
                                className="edit-item-btn"
                                onClick={() => {
                                    createProperty(cellProps.row.original.id)
                                }}
                            >
                                <i className="ri-save-2-fill align-bottom me-2 text-muted"/> Créer le bien {cellProps.row.original.property_id != null ? '(déjà)' : ''}
                            </DropdownItem>
                        </li>
                    );

                    return (
                        <UncontrolledDropdown>
                            {button}
                            <DropdownMenu className="dropdown-menu-end">
                                {save}
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    );
                },
            },
        ]
    }, []);

    return (
        <>
            <Row className="mb-3">
                <Col sm={4}>
                    <ReactSelect
                        id="site"
                        name="site"
                        placeholder="Veuillez sélectionner le site"
                        value={site}
                        onChange={handleSiteChange}
                        options={filterData.sites}
                        isLoading={loadFilterData}
                    />
                </Col>

                <Col sm={4}>
                    <ReactSelect
                        id="building"
                        name="building"
                        placeholder="Veuillez sélectionner le batiment"
                        value={building}
                        onChange={handleBuildingChange}
                        options={filterData.buildings}
                        isLoading={loadFilterData}
                    />
                </Col>

                <Col sm={4}>
                    <ReactSelect
                        id="local"
                        name="local"
                        placeholder="Veuillez sélectionner le local"
                        value={local}
                        onChange={value => {setLocal(value)}}
                        options={filterData.locals}
                        isLoading={loadFilterData}
                    />
                </Col>
            </Row>
            <Row>
                <Col sm={4}>
                    <ReactSelect
                        id="staff"
                        name="staff"
                        placeholder="Filtrer par inventoriste"
                        value={staff}
                        onChange={handleStaffChange}
                        options={users}
                        isLoading={loadFilterData}
                    />
                </Col>
            </Row>

            <DbDatatable
                columns={columns}
                data={entities ? entities.data : []}
                className="custom-header-css"
                loading={entitiesLoading}
                fetchData={fetchData}
                perPage={entities ? entities['per_page'] : undefined}
                totalPage={entities ? entities['last_page'] : undefined}
            />

            <Modal id="imageViewer" isOpen={imageViewerModal}  size="md" className="border-0" modalClassName="zoomIn">
                <ModalBody>
                    {
                        currentImage &&
                        <Row className="g-3 text-center">
                            <img src={currentImage} alt=""  className="img-fluid rounded" />
                        </Row>
                    }

                </ModalBody>

                <ModalFooter>
                    <Button color="light" onClick={() => setImageViewerModal(false)}>
                        Fermer
                    </Button>
                </ModalFooter>
            </Modal>

        </>
    )
};

export default NewPropertiesFound;